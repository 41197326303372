



























import {
  Component, PropSync, Vue,
} from 'vue-property-decorator';
import EditEmergencyFundGoalPlanDialogViewModel
  from '@/vue-app/view-models/components/goals-dashboard/edit-plan-goals/edit-emergency-fund-goal-plan/edit-emergency-fund-goal-plan-dialog-view-model';

@Component({
  name: 'EditEmergencyFundGoalPlanDialog',
  components: {
    EditEmergencyFund: () => import('./EditEmergencyFund.vue'),
    EditEmergencyFundYourBudget: () => import('./EditEmergencyFundYourBudget.vue'),
    EditEmergencyFundYourCurrentAmount: () => import('./EditEmergencyFundYourCurrentAmount.vue'),
    EditEmergencyFundDefineBy: () => import('./EditEmergencyFundDefineBy.vue'),
    EditEmergencyFundNewFund: () => import('./EditEmergencyFundNewFund.vue'),
    EditEmergencyFundSuccess: () => import('./EditEmergencyFundSuccess.vue'),
  },
})
export default class EditEmergencyFundGoalPlanDialog extends Vue {
  @PropSync('investorGoalId', { type: String })
  synced_investor_goal_id!: string;

  @PropSync('isOpen', { type: Boolean })
  synced_is_open!: boolean;

  link_strategy_moderate_wealth_model = Vue.observable(
    new EditEmergencyFundGoalPlanDialogViewModel(this),
  );

  closeModal() {
    this.synced_is_open = false;
  }
}
